.layout {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.flex-container {
  flex-direction: row;
  align-items: center;
}
.sidebar-container {
  width: 300px;
  min-height: 100vh;
  padding: 20px;
}
.loginContainer {
  width: calc(100% - 300px);
  min-height: 100vh;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginForm {
  width: 400px;
  margin-top: 30px;
}
.form-inputs .MuiInput-root input:focus {
  border: none !important;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: -internal-light-dark(black, white) !important;
}
.caption {
  font-family: "roboto-bold";
  text-align: center;
}

.form-inputs {
  margin-top: 30px;
}
.form-btn {
  text-align: center;

  margin-top: 50px;
}
.form-btn .btn {
  background-color: #e27e2f;
  color: white !important;
  font-family: "roboto-semi";
  width: 100%;
  font-weight: bold;
  padding: 2px;
  font-size: 20px;
}
.error {
  color: #d60e0e;
  font-family: "roboto-medium";
  font-size: 16px;
  padding-top: 5px;
}
.form-btn .btn:focus {
  outline: 0;
  box-shadow: none !important;
}

@media screen and (max-width: 768px) {
  .flex-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .sidebar-container {
    width: 100%;
    min-height: 100px;
    padding: 20px;
  }
  .loginContainer {
    width: 98%;
    min-height: auto;
    background-color: #fff;
    padding: 20px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loginForm {
    width: 80%;
    margin-top: 40px;
  }
}
