.sidebar-title {
  font-family: "roboto-semi";
  color: white;
  text-align: center;
}

.clear-pm {
  padding: 0 !important;
  margin: 0 !important;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  width: 320px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 0.4px solid #e7e7e8;
  overflow-x: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 10px #7777771a;
  opacity: 1;
}

.sidebar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  height: 90px;
  background: #e37d20;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 0px;

  background: #ffffff;
}
.sidebar-items .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 30px;

  cursor: pointer;
  border-left: 5px solid #ffffff;
  transition: background-color 0.2s;
}
.sidebar
.sidebar-items  svg{
  width: 30px!important;
  height: 30px !important;
}
.sidebar-collapsed
.sidebar-items  svg{
  width: 50px!important;
  height: 50px !important;
}
.sidebar-icon {
  /* width: 25px; */
  color: #aaaaaa;
  /* height: 25px; */
  user-select: none;
  cursor: pointer;
  margin-right: 16px;
}

.sidebar-header-text {
  display: block;
  font-family: "roboto-bold";
  font-size: 30px;
  color: white;
}

.sidebar-section {
  display: block;
  border-bottom: 0.4px solid #e7e7e8;
  padding: 30px 0px;
  width: 100%;
}
.section-header-text {
  display: block;
  color: #abafb3;
  font-family: 12px;
  margin-left: 20px;
  font-family: "roboto-medium";
}

.sidebar-section .item:hover {
  background: #e3e4e6e0;
  border-left: 5px solid #bbbcbde0;
  /* box-shadow: 0px 10px 30px #0000001a; */
}
.sidebar-item--text {
  display: block;
  color: #6a707e;
  font-family: "roboto-medium";
  font-size: 20px;
}
.item:hover .sidebar-item--text,
.item:hover .sidebar-icon {
  color: #7f63f4;
}

.item-active {
  color: #7f63f4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 30px;

  cursor: pointer;

  border-left: 5px solid #7f63f4;
  transition: background-color 0.2s;
  background: #ffe8d2 0% 0% no-repeat padding-box;
}

.item-active .sidebar-icon,
.item-active .sidebar-item--text {
  color: #7f63f4;
}
.item-active .sidebar-icon {
  height: 30px;
  width: 30px;
}
.sidebar-collapsed {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100%;
  overflow-x: none;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #abafb3;
  border-right: 1px solid #f1d5ba;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 10px #7777771a;
  opacity: 1;
}
/* .sidebar .section-header-text,
  .sidebar-item--text {
    display: block;
  } */
/* .sidebar-collapsed .section-header-text,
  .sidebar-item--text {
    display: none;
  } */

.sidebar-collapsed .sidebar-icon {
  /* width: 60px;
  height: 60px; */
  margin-right: 0;
}

.nav-header {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border-color: black;
  background-color: blue;
  margin-right: 10px;
}
.content-area {
  background-color: #f3f3f3;
  padding: 30px;

  margin-left: 320px;
  min-height: 100vh;
  /* height: 100vh; */
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px !important ;
}
.sidebar-header-Icon {
  display: block;
}

@media screen and (max-width: 992px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow-x: none;
  }
  .content-area {
    margin-left: 100px !important;
  }
  .section-header-text,
  .sidebar-item--text,
  .sidebar-header-Icon {
    display: none !important;
  }
  .sidebar-icon {
    margin-right: 0;
  }
}

@media screen and (max-height:768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 0.4px solid #e7e7e8;
    overflow-x: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 10px #7777771a;
    opacity: 1;
    overflow-y: scroll;
  }
  .sidebar-collapsed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100%;
    overflow-x: none;
    overflow-y: scroll;
    
    display: flex;
    flex-direction: column;
    border-right: 1px solid #abafb3;
    border-right: 1px solid #f1d5ba;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 10px #7777771a;
    opacity: 1;
  }
  
}