@font-face {
  font-family: "roboto-regular";
  src: url(../fonts/montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-bold";
  src: url(../fonts/montserrat/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-semi";
  src: url(../fonts/montserrat/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-light";
  src: url(../fonts/montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "roboto-medium";
  src: url(../fonts/montserrat/Montserrat-Medium.ttf) format("truetype");
}
