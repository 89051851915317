.home-wrapper .layout {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.home-wrapper .flex-container {
  flex-direction: row;
  align-items: flex-start;
}
.home-wrapper .sidebar-container {
  width: 400px;
  min-height: 100vh;
  padding: 20px;
  position: fixed;
}
.note-text {
  font-size: 14px;
  font-weight: 800;
  padding-bottom: 10px;
  color: #6b6868;
}
.note-sub {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
  color: #be9191;
}
.home-wrapper .loginContainer {
  /* width: calc(100% - 400px); */
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 400px;
  overflow-x: auto;
}
/* 
input::placeholder {
  color: #878585 !important;
  font-weight: 500;
} */
#consumption table th {
  font-size: 12px;
}

#consumption table td {
  font-size: 14px;
}
#consumption .loginForm {
  padding: 0px;
  margin-top: 0px;
}
.home-wrapper .loginForm {
  width: 100%;
  margin-top: 0px;
  padding: 20px;
}

.home-wrapper .caption {
  font-family: "roboto-bold";
  text-align: left;
  padding-bottom: 2px;
  font-size: 23px;
}

.home-wrapper .form-inputs {
  margin-top: 20px;
  width: 100%;
  position: relative;
}
.error-position {
  position: absolute;
  right: 10px;
  background-color: #fff;
  padding: 0px 5px;
  bottom: 15px;
}
.home-wrapper .form-inputs label {
  color: #e27e2f;

  font-family: "roboto-semi";
  padding-bottom: 5px;
  display: block;
}
.home-wrapper .select-blabel--container {
  margin-top: 20px;
  width: 100%;
  position: relative;
}

.home-wrapper .select-blabel {
  color: #e27e2f;

  font-family: "roboto-semi";
  padding-bottom: 5px;
  display: block;
}

.question {
  font-family: "roboto-medium";
  margin-left: 10px;
  font-size: 19px;
}

.question-margin {
  margin-top: 13px !important;
}
.modal-form-inputs label {
  color: #e27e2f;

  font-family: "roboto-semi";
  padding-bottom: 8px;
  display: block;
  margin-top: 10px;
}

.modal-form-inputs {
  position: relative;
}

.modal-form-inputs input {
  width: 100%;

  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 6px 5px;
}
.form-control:focus {
  box-shadow: none !important;
}
.home-wrapper .form-inputs input {
  padding: 8px 6px;
  border-radius: 5px;
  border: 1px solid #abaaaa;
  width: 100%;
}
.home-wrapper .form-inputs .error-input {
  border: 1px solid #d60e0e !important;
}

.home-wrapper .form-header {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 2px solid #002c42;
  margin-bottom: 10px;
}
.home-wrapper .form-btn {
  text-align: right;
  width: 150px;

  margin-top: 50px;
  margin-left: auto;
}
.form-btn-row {
  text-align: right;
  display: flex;

  margin-top: 50px;
  margin-left: auto;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
}

.home-wrapper .form-btn-row .prev {
  /* background-color: #918c8c; */
  color: white !important;
  font-family: "roboto-semi";

  font-weight: bold;
  padding: 10px 20px;
  font-size: 18px;
}
.subcaption {
  font-family: "roboto-semi";
  text-align: left;
  padding-bottom: 2px;
  font-size: 16px;
  margin-top: 30px;
}

.home-wrapper .form-btn-row .new {
  /* background-color: #34c38f; */
  color: white !important;
  font-family: "roboto-semi";

  font-weight: bold;
  padding: 10px 20px;
  font-size: 18px;
}

.new {
  background-color: #34c38f;
  color: white !important;
  font-family: "roboto-semi";

  font-weight: bold;
  padding: 10px 20px;
  font-size: 18px;
}
.close {
  background-color: red !important;
  color: white !important;
  font-family: "roboto-semi";

  font-weight: bold;
  padding: 10px 20px;
  font-size: 20px;
}

.home-wrapper .form-btn-row .next {
  background-color: #e27e2f;
  color: white !important;
  font-family: "roboto-semi";

  font-weight: bold;
  padding: 10px 20px;
  font-size: 18px;
}
.home-wrapper .form-btn .btn {
  background-color: #e27e2f;
  color: white !important;
  font-family: "roboto-semi";

  font-weight: bold;
  padding: 8px;
  /* font-size: 20px; */
}
.required {
  color: #d60e0e !important;
}
.step-wizard {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.step-content {
  padding: 20px 30px;
  margin-top: 30px;
}
.step-content p {
  color: #fff;
  font-family: "roboto-semi";
  margin-top: 20px;
}

.icons {
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icons h2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #3b3b3b;
  font-family: "roboto-bold";
}
.line {
  position: absolute;
  width: 300px;
  height: 2px;
  background-color: #fff;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.step {
  width: 50px;
  height: 50px;
  z-index: 2;
  border-radius: 50%;
  background-color: white;
  color: #e27e2f;
  font-family: "roboto-bold";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
.step-active {
  width: 50px;
  font-size: 18px;
  height: 50px;
  border-radius: 50%;
  background-color: #3b3b3b;
  color: #fff;
  font-family: "roboto-bold";
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.home-wrapper .error {
  color: #d60e0e;
  font-family: "roboto-medium";
  font-size: 14px;
}
.home-wrapper .form-btn .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.table-container {
  margin-top: 40px;
}
.table-container th {
  text-align: center;
  vertical-align: middle;
}
.table-container td {
  text-align: center;
  vertical-align: middle;
}

@media screen and (max-width: 992px) {
  .home-wrapper .flex-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .home-wrapper .sidebar-container {
    width: 100%;
    min-height: 100px;
    padding: 20px;
    position: relative;
  }
  .home-wrapper .loginContainer {
    width: 99%;
    min-height: auto;
    background-color: #fff;
    padding: 20px;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .home-wrapper .loginForm {
    width: 98%;
    margin-top: 20px;
    padding: 10px;
  }
  .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .step-wizard {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin: 50px 50px;
  }
}

@media screen and (max-width: 768px) {
  .home-wrapper .form-btn {
    text-align: center;
    width: 100%;

    margin-top: 50px;
    margin-left: auto;
  }
  .question {
    font-family: "roboto-medium";
    margin-left: 10px;
    font-size: 20px;
  }
  .home-wrapper .form-btn-row {
    text-align: right;
    display: flex;
    margin-top: 50px;
    margin-left: auto;
    width: 100%;
    gap: 10px;
    justify-content: flex-end;
    flex-direction: column;
  }
}
@media screen and (max-width: 556px) {
  .step-wizard {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin: 50px 25px;
  }
  .home-wrapper .caption {
    font-family: "roboto-bold";
    text-align: center;
    padding-bottom: 2px;
    font-size: 18px;
  }
}
