@import "./assets/css/typography.css";
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: "roboto-regular";
  overflow-x: hidden;
}
div {
  font-family: "roboto-regular";
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  margin-bottom: 0 !important;
}
h1 {
  font-family: "roboto-regular";
  font-size: 42px;
}
input,
button,
textarea,
select {
  font-family: inherit;
}

input:focus {
  border: 1px solid #f27022 !important;
}

input:focus-visible {
  outline: none !important;
}

label {
  margin-bottom: 0 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b8bec5 !important;
  font-family: "roboto-light";
  font-size: 14px;
  text-overflow: ellipsis;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b8bec5 !important;
  font-family: "roboto-light", "san-serif";
  font-size: 14px;
  text-overflow: ellipsis;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b8bec5 !important;
  font-family: "roboto-light", "san-serif";
  font-size: 14px;
  text-overflow: ellipsis;
}

.primaryColor {
  color: #e27e2f !important;
}
.bgColor {
  background-color: #e27e2f !important;
}

.secondaryColor {
  color: #3b3b3b !important;
}
/* #f27022  orange */
/* #002c42  blue */
/* #3b3b3b  grey */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(228, 172, 120, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;

}
/* 
.modal-form-inputs .css-1s2u09g-control{
  
    border: none!important;
  
}

.css-1s2u09g-control{
  border: none!important;
} */