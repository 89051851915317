.table-data {
  text-align: center !important;
}
.table-header-text th {
  color: #464a53;
  font-size: 16px;
}
.main-table {
  height: 100vh !important;
}
.table-data-row {
  padding: 10px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 20px 20px #0000001a;
  opacity: 1;
  border: 1px solid #7a41411a;
}
.table-data-row td {
  padding: 14px;
  color: #6a707e;
  font-family: "roboto-semi";
  vertical-align: middle;
}

.costViewBtn {
  background-color: #35b968 !important;
  color: white !important;
  border-radius: 20px !important;
}
.costViewBtn-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 20px !important;
}
.header-text {
  font-family: "roboto-semi";

  color: #6a707e;
}
.accordion-header-text {
  font-family: "roboto-semi";
  font-size: 18px;
  color: #6a707e;
}

.card-container {
  margin: 20px;
  background-color: white;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  padding: 50px;
}


.lead-card-container {
  margin: 20px;
  background-color: white;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  padding: 10px;
}
.lead-tab-container{


margin: 10px;
/* border-radius: 10px; */
background-color: white;
/* border: 1px solid rgb(184, 184, 184); */
box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
-webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
-moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}
.lead-tab-container .tab-item{
  text-align: center;
  color: #6a707e;
  font-family: "roboto-medium";
  cursor: pointer;
  padding: 10px;
  overflow: hidden;

}
.lead-tab-container .tab-item:hover{
  text-align: center;
  background-color: #6a707e;
  font-family: "roboto-medium";
  cursor: pointer;
  padding: 10px;
  overflow: hidden;
  color: white;

}
.consumptionlist table thead th,.consumptionlist table tbody td{
  vertical-align: middle;
  text-align: center;
  font-size: 14px !important;
}

.personal table tr th, .personal table tr td {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
}

.personal table tr td {
  font-weight: 500 !important;
}

.personal {
 margin:10px;
 margin-top: 20px !important;
}

.epi-box .lastrowval{
  font-size: 25px;
  color: #00583e;
  font-weight: bold;
}

.epi-box {
  padding: 10px;
  background-color: #8dc143 !important;
  margin: 0px;
  margin-bottom: 15px;
  /* background-color: #e27024; */
}

.epi-box p {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}

.epi-result {
  padding: 20px;
  text-align: center;
}

.tab-item-active {
  color: #7f63f4;
  padding: 10px;
  overflow: hidden;
 
  cursor: pointer;
 
  transition: background-color 0.2s;
  background: #ffe8d2 0% 0% no-repeat padding-box;
}

.border-right{
border-right: 1px solid rgb(184, 184, 184);
}


.createBtn {
  background-color: #35b968 !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 8px 45px !important;
}
.deleteBtn {
  background-color: red !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 8px 45px !important;
  margin-right: 10px !important;
}

#app_type_c .css-1s2u09g-control,
#app_type_c .css-6j8wv5-Input,
#app_type_c .css-1pahdxg-control {
  min-height: 50px;
}

.tariff-error-position p {
  color: red;
  font-family: "roboto-semi";
}
.tariff-error-position {
  position: absolute;
  right: 12px;
  bottom: -10px;
  padding: 0px 8px;
  background: white;
}
.addInput {
  padding: 14px !important;
}
.dropdown-container {
  padding: 10px;
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: -100px;
  background-color: white;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}

.dropdown-item {
  font-family: "roboto-semi";
  color: "#6A707E";
}
.dropdown-item:hover {
  background-color: #abafb3;
}
.accordion-container {
  background-color: white;
  padding: 12px;
  margin-top: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}
.accordion-inside {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.create-label {
  font-family: "roboto-semi";
  color: #6a707e;
  padding: 8px 0px;
}

.table_error_position {
  position: absolute;
  right: 5px;
  bottom: -6px;
  padding: 0 5px;
  background-color: white;
}
.table_error_position p {
  color: #dc3545;
  font-family: "roboto-semi";
  font-size: 12px;
  margin-bottom: 0px !important;
}
.acos_table_edit tbody tr {
  vertical-align: middle;
}

.acos_table_edit tbody tr td {
  padding: 10px !important;
}

.acos_table tr {
  vertical-align: center !important;
}

.loader-position {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.deleteModal-header {
  background-color: #e37d20;
}
.deleteModal-headerText {
  color: white;
  font-family: demiFont;
}
.deleteModal-body {
  margin-bottom: 30px;
}
.deleteModal-bodyText {
  color: #6a707e !important;
  font-family: "roboto-semi" !;
}

.modalcloseBtn {
  color: #6a707e !important;
  border-radius: 20px !important;
  margin: 5px 10px !important;
  padding: 8px 25px !important;
  background-color: white !important;
  border: 1px solid #6a707e !important;
}
.modalDeleteBtn {
  background-color: white !important;
  border: 1px solid #e37d20 !important;
  color: #e37d20 !important;
  border-radius: 20px !important;
  padding: 8px 25px !important;
}

.tariff-success {
  border: 1px solid green;
  padding: 8px;
  color: white;
  margin-top: 10px;
  text-align: center;
  font-family: "roboto-semi";
  font-size: 20px;
  border-radius: 10px;
  margin-bottom: 26px;
  background-color: #64d464;
}
.tariff-failed {
  border: 1px solid red;
  padding: 8px;
  color: white;
  text-align: center;
  font-family: "roboto-semi";
  font-size: 20px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 26px;
  background-color: rgba(243, 54, 54, 0.829);
}

/*Dashboard*/
.desc-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.desc-input {
  width: 90%;
  position: relative;
  margin: 20px 0px;
}
.desc-link {
  color: #64d464;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
.desc-remove {
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.dashboard-main {
  width: 100%;
}
.unit-chart {
  background-color: white;
  border-radius: 5px;
  padding-bottom: 20px;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}

.total-chart {
  background-color: white;
  border-radius: 5px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -webkit-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
  -moz-box-shadow: -1px 8px 7px -3px rgba(72, 61, 61, 0.73);
}
.content-width {
  width: 30%;
  max-height: 300px;
}
.inner-chart {
  padding: 30px;
  padding-left: 50px;

  padding-right: 50px;
}
/* .select-box {
    padding: 50px !important;
  } */
.select-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.content-width-select {
  width: 49%;
}
.line {
  background-color: black;
}

.tab-container {
  background-color: white;

  border: 1px solid #aaaaaa;
  margin-left: 0px !important;
  border-radius: 10px;
  padding: 0px !important;
  overflow: hidden;
}

.tab-container .col {
  text-align: center;
  font-family: demiFont;
  vertical-align: middle;
  justify-content: center;
  cursor: pointer;
}

.tab-title {
  color: #6a707e;
  padding: 18px;
  font-family: "roboto-semi";
  font-size: 18px;
  margin-bottom: 0px !important;
}

.tab-margin {
  border-right: 0.8px solid #aaaaaa;
}

.tab-container .col:hover {
  background-color: #ffe8d2c9;
  cursor: pointer;
}

.tab__active {
  background: #ffe8d2 0% 0% no-repeat;
  border-bottom: 5px solid #7f63f4;
}

.tab__active p {
  color: #7f63f4;
  padding: 18px;
  font-family: "roboto-semi";
  font-size: 18px;
}

.dashboard-container .select-container {
  justify-content: space-between;
}
