
/* 
button {
  margin-right: 10px;
  background: #3f51b5;
  color: white;
  border: none;
  padding: 5px 10px;
  width: 100px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;
} */

/* For pdf */
.react-pdf__Page {
  margin-top: 8px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  padding: 20px;
  width: 100% !important;
  height: 100% !important;

}

/* For all pages */
.pdfContainer {
  height: 100%;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
